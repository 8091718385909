exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-ajustes-index-tsx": () => import("./../../../src/pages/admin/ajustes/index.tsx" /* webpackChunkName: "component---src-pages-admin-ajustes-index-tsx" */),
  "component---src-pages-admin-categoria-de-noticias-[id]-tsx": () => import("./../../../src/pages/admin/categoria-de-noticias/[id].tsx" /* webpackChunkName: "component---src-pages-admin-categoria-de-noticias-[id]-tsx" */),
  "component---src-pages-admin-categoria-de-noticias-nuevo-index-tsx": () => import("./../../../src/pages/admin/categoria-de-noticias/nuevo/index.tsx" /* webpackChunkName: "component---src-pages-admin-categoria-de-noticias-nuevo-index-tsx" */),
  "component---src-pages-admin-categorias-de-noticias-index-tsx": () => import("./../../../src/pages/admin/categorias-de-noticias/index.tsx" /* webpackChunkName: "component---src-pages-admin-categorias-de-noticias-index-tsx" */),
  "component---src-pages-admin-components-admin-table-data-index-tsx": () => import("./../../../src/pages/admin/components/adminTableData/index.tsx" /* webpackChunkName: "component---src-pages-admin-components-admin-table-data-index-tsx" */),
  "component---src-pages-admin-components-pagination-index-tsx": () => import("./../../../src/pages/admin/components/pagination/index.tsx" /* webpackChunkName: "component---src-pages-admin-components-pagination-index-tsx" */),
  "component---src-pages-admin-components-quill-editor-index-tsx": () => import("./../../../src/pages/admin/components/quill-editor/index.tsx" /* webpackChunkName: "component---src-pages-admin-components-quill-editor-index-tsx" */),
  "component---src-pages-admin-components-sidenav-index-tsx": () => import("./../../../src/pages/admin/components/sidenav/index.tsx" /* webpackChunkName: "component---src-pages-admin-components-sidenav-index-tsx" */),
  "component---src-pages-admin-configuracion-de-perfil-index-tsx": () => import("./../../../src/pages/admin/configuracion-de-perfil/index.tsx" /* webpackChunkName: "component---src-pages-admin-configuracion-de-perfil-index-tsx" */),
  "component---src-pages-admin-contexts-auth-tsx": () => import("./../../../src/pages/admin/contexts/auth.tsx" /* webpackChunkName: "component---src-pages-admin-contexts-auth-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-login-index-tsx": () => import("./../../../src/pages/admin/login/index.tsx" /* webpackChunkName: "component---src-pages-admin-login-index-tsx" */),
  "component---src-pages-admin-noticias-[id]-tsx": () => import("./../../../src/pages/admin/noticias/[id].tsx" /* webpackChunkName: "component---src-pages-admin-noticias-[id]-tsx" */),
  "component---src-pages-admin-noticias-index-tsx": () => import("./../../../src/pages/admin/noticias/index.tsx" /* webpackChunkName: "component---src-pages-admin-noticias-index-tsx" */),
  "component---src-pages-admin-noticias-nueva-index-tsx": () => import("./../../../src/pages/admin/noticias/nueva/index.tsx" /* webpackChunkName: "component---src-pages-admin-noticias-nueva-index-tsx" */),
  "component---src-pages-admin-tags-de-noticias-[id]-tsx": () => import("./../../../src/pages/admin/tags-de-noticias/[id].tsx" /* webpackChunkName: "component---src-pages-admin-tags-de-noticias-[id]-tsx" */),
  "component---src-pages-admin-tags-de-noticias-index-tsx": () => import("./../../../src/pages/admin/tags-de-noticias/index.tsx" /* webpackChunkName: "component---src-pages-admin-tags-de-noticias-index-tsx" */),
  "component---src-pages-admin-tags-de-noticias-nuevo-index-tsx": () => import("./../../../src/pages/admin/tags-de-noticias/nuevo/index.tsx" /* webpackChunkName: "component---src-pages-admin-tags-de-noticias-nuevo-index-tsx" */),
  "component---src-pages-admin-usuario-[id]-tsx": () => import("./../../../src/pages/admin/usuario/[id].tsx" /* webpackChunkName: "component---src-pages-admin-usuario-[id]-tsx" */),
  "component---src-pages-admin-usuarios-index-tsx": () => import("./../../../src/pages/admin/usuarios/index.tsx" /* webpackChunkName: "component---src-pages-admin-usuarios-index-tsx" */),
  "component---src-pages-admin-usuarios-nuevo-index-tsx": () => import("./../../../src/pages/admin/usuarios/nuevo/index.tsx" /* webpackChunkName: "component---src-pages-admin-usuarios-nuevo-index-tsx" */),
  "component---src-pages-category-fotogalerias-components-gallery-content-tsx": () => import("./../../../src/pages/category/fotogalerias/components/galleryContent.tsx" /* webpackChunkName: "component---src-pages-category-fotogalerias-components-gallery-content-tsx" */),
  "component---src-pages-category-fotogalerias-index-tsx": () => import("./../../../src/pages/category/fotogalerias/index.tsx" /* webpackChunkName: "component---src-pages-category-fotogalerias-index-tsx" */),
  "component---src-pages-contacto-components-contact-content-tsx": () => import("./../../../src/pages/contacto/components/contactContent.tsx" /* webpackChunkName: "component---src-pages-contacto-components-contact-content-tsx" */),
  "component---src-pages-contacto-index-tsx": () => import("./../../../src/pages/contacto/index.tsx" /* webpackChunkName: "component---src-pages-contacto-index-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-news-news-page-tsx": () => import("./../../../src/pages/news/news-page.tsx" /* webpackChunkName: "component---src-pages-news-news-page-tsx" */),
  "component---src-pages-politica-de-privacidad-components-policy-content-tsx": () => import("./../../../src/pages/politica-de-privacidad/components/policyContent.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-components-policy-content-tsx" */),
  "component---src-pages-politica-de-privacidad-index-tsx": () => import("./../../../src/pages/politica-de-privacidad/index.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-index-tsx" */),
  "component---src-pages-quienes-somos-components-we-content-tsx": () => import("./../../../src/pages/quienes-somos/components/weContent.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-components-we-content-tsx" */),
  "component---src-pages-quienes-somos-index-tsx": () => import("./../../../src/pages/quienes-somos/index.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-index-tsx" */)
}

